import React from "react";
import FilterPage from "./../FilterPage/FilterPage";
import ResourcesOverview from "./ResourcesOverview";
import ResourceListItem from "./ResourceListItem";
import ResourcesResultsControls from "./ResourcesResultsControls";
import ResourcesBasketPopUp from "./ResourcesBasketPopUp";
import ResourceTreeNodeNew from "./ResourceTreeNodeNew";
import Api from "../../Api";
import { Row } from "react-bootstrap";
import PopUp from "../PopUp";
import ResourceForm from "../UserProfile/CreateResource";

class ResourcesFilterPage extends React.Component {
    constructor(props) {
        super(props);

        this.category = null;
        this.criteria = props.profile ? [props.profile] : [];

        // default sorting
        this.order = "viewCount";
        this.direction = "asc";
        this.user = props.user;

        this.state = {
            categories: [],
            resources: [],
            loading: false,
            hasResults: false,
            searched: false,
            totalResults: false,
            resultsView: "grid",
            basket: this.getBasketFromCookie(),
            basketModalShow: false,
            resourceIndexParents: [],
            activeResourceIndex: null,
            resourceIndexChildren: [],
            selectableIds: false,
            advertBanner: null,
        };

        this.searchInputRef = React.createRef();
        this.scrollRef = React.createRef();
    }

    componentDidMount() {
        this.props.setFilterPageClass();

        const promises = [
            Api.categories(),
            Api.resourceTrees({ isEnabled: true, "exists[parent]": false }),
            Api.advertBanners(true, "resource"),
        ];

        const hasBasket = this.state.basket.length > 0 ? true : false;

        if (hasBasket) {
            promises.push(Api.resources({ id: this.state.basket }));
        }

        Promise.all(promises).then((responses) => {
            const categories = responses[0];
            const resourceIndexParents = responses[1];
            const advertBanner =
                responses[2] && responses[2].length ? responses[2][0] : null;
            const basket = hasBasket ? responses[3] : [];

            this.setState({
                categories,
                resourceIndexParents,
                basket,
                advertBanner,
            });
        });

        this.loadSessionStorage();
    }

    loadSessionStorage() {
        const urlParams = new URLSearchParams(window.location.search);
        const paramIds = urlParams.get("ids");

        if (!paramIds) {
            const sessionCriteriaString =
                window.sessionStorage.getItem("resourceCriteria");
            const sessionCriteria = JSON.parse(sessionCriteriaString);

            if (sessionCriteria) {
                this.onCriteriaChange(sessionCriteria);
            }

            const sessionCategoryString =
                window.sessionStorage.getItem("resourceCategory");
            const sessionCategory = JSON.parse(sessionCategoryString);

            if (sessionCategory) {
                this.onCategoryChange(sessionCategory);
            }
        }
    }

    scrollTo() {
        this.scrollRef.current.scrollIntoView({ behavior: "smooth" });
    }

    getBasketFromCookie = () => {
        let cookie = {};

        document.cookie.split(";").forEach(function (el) {
            let [key, value] = el.split("=");
            cookie[key.trim()] = value;
        });

        return cookie["basketIds"] ? JSON.parse(cookie["basketIds"]) : [];
    };

    componentWillUnmount() {
        this.props.setFilterPageClass(true);
    }

    /**
     * When a category is changed, update it and re-search
     */
    onCategoryChange = (category) => {
        this.category = category;
        this.search();

        window.sessionStorage.setItem(
            "resourceCategory",
            JSON.stringify(category)
        );
    };

    /**
     * When a resource is favourite'd, update the user favourites
     */
    onFavourite = (id) => {
        Api.userFavourites(id)
            .then((response) => {
                this.props.loginRefresh();
            })
            .catch((err) => console.error(err));
    };

    /**
     * Toggle between list/grid view
     */
    onViewChange = (resultsView) => {
        this.setState({ resultsView });
    };

    /**
     * When an item is added to the basket
     */
    onAddToBasket = (resource) => {
        let included = false;

        for (var i = 0; i < this.state.basket.length; i++) {
            if (this.state.basket[i].id === resource.id) {
                included = true;
            }
        }

        if (!included) {
            let basket = [...this.state.basket, resource];
            this.setState({ basket });
            this.updateBasketCookie(basket);
        }
    };

    /**
     * When an item is removed from the basket
     */
    onRemoveFromBasket = (resource) => {
        let basket = [...this.state.basket];

        basket = basket.filter(function (item) {
            return item !== resource;
        });

        this.setState({ basket });
        this.updateBasketCookie(basket);
    };

    /**
     * Update the basket cookie whenever it is changed
     */
    updateBasketCookie = (basket) => {
        let ids = [];

        basket.forEach((item) => {
            ids.push(item.id);
        });

        let jsonIds = JSON.stringify(ids);
        document.cookie = "basketIds=" + jsonIds;
    };

    /**
     * When an item is added to the search
     */
    onCriteriaChange = (criteria) => {
        this.scrollTo();
        this.criteria = criteria;

        if (this.criteria.length > 0) {
            this.search();
        } else {
            this.setState({
                resources: [],
                searched: false,
                selectableIds: false,
            });
        }

        window.sessionStorage.setItem(
            "resourceCriteria",
            JSON.stringify(this.criteria)
        );
    };

    /**
     * When the propety to sort by changes
     */
    onSortChange = (order) => {
        this.order = order;
        this.search();
    };

    /**
     * When the direction of the sort changes
     */
    onDirectionChange = (direction) => {
        this.direction = direction;
        this.search();
    };

    /**
     * Toggle the basket modal
     */
    toggleBasketModalPopUp = () => {
        this.setState({
            basketModalShow: this.state.basketModalShow ? false : true,
        });
    };

    addCriteria = (criteria) => {
        this.searchInputRef.current.addCriteria(criteria);
    };

    removeCriteria = (criteria) => {
        this.searchInputRef.current.removeCriteria(criteria);
    };

    clearCriteria = () => {
        this.searchInputRef.current.clearCriteria();
    };

    onProfileClick = (profile) => {
        let isCriteria = false;

        this.criteria.forEach((item, index) => {
            if (item.id === profile.id) {
                isCriteria = true;
            }
        });

        if (!isCriteria) {
            this.addCriteria(profile);
        } else {
            this.removeCriteria(profile);
        }
    };

    onResourceIndexClick = (index) => {
        this.scrollTo();
        this.setState({
            activeResourceIndex: index,
            resourceIndexChildren: [],
        });
        this.clearCriteria();

        let treeTerms = [...index.criteria];

        if (treeTerms && treeTerms.length) {
            let that = this;

            setTimeout(function () {
                that.addCriteria(treeTerms);
            }, 500);
        }

        Api.resourceTrees({ isEnabled: true, parent: index.id }).then(
            (resourceIndexChildren) => {
                this.setState({ resourceIndexChildren });
            }
        );

        this.setState({ searched: true });
    };

    search() {
        const ids = this.criteria
            .filter((criteria) => criteria.id)
            .map((criteria) => criteria.id)
            .join(",");

        const query = {
            resource_type: "resource",
            relationship_ids: ids,
            and_join: "1",
            search_type: "resourceSearchCount",
            include_relationships: "1",
        };

        const resourceTitle = this.criteria.find(
            (criteria) =>
                criteria.profileType.title ===
                "Resource title contains the following phrase"
        );

        if (resourceTitle) {
            query.query = resourceTitle.title;
        }

        if (this.category) {
            query.category_id = this.category.id;
        }

        if (this.order) {
            query.order_by_property = this.order;
        }

        if (this.direction) {
            query.order_by_direction = this.direction;
        }

        this.setState({ loading: true, searched: true });

        Api.search(query)
            .then(({ data, total_items }) => {
                let selectableIds = [];

                this.criteria.forEach((criteria) => {
                    selectableIds.push(String(criteria.id));
                });

                data.forEach((item) => {
                    if (item.relationships) {
                        item.relationships.forEach((id) => {
                            selectableIds.push(String(id));
                        });
                    }
                });

                selectableIds = [...new Set(selectableIds)];

                this.setState({
                    loading: false,
                    resources: data,
                    totalResults: data.length,
                    selectableIds,
                });
            })
            .catch((err) => console.error(err));
    }

    toggleForm = () => {
        this.setState({ showForm: this.state.showForm ? false : true });
    };

    render() {
        let favouriteIds = [];

        if (this.props.user && this.props.user.favourites) {
            this.props.user.favourites.forEach((item, index) => {
                favouriteIds.push(item.id);
            });
        }

        // content that will be displayed on the page will be assigned to this variable
        let pageContent;
        let preFilterContent;

        // the default homepage
        if (this.state.searched === false && this.state.loading === false) {
            pageContent = (
                <>
                    <div className="resource-filter-page-content">
                        {this.props.introContent && (
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: this.props.introContent
                                        .resourcesContent,
                                }}
                            ></div>
                        )}
                    </div>

                    {/* Most recent resources */}
                    <ResourcesOverview
                        user={this.props.user}
                        onFavourite={this.onFavourite}
                        orderBy="order[createdAt]"
                        orderDir="desc"
                        title="Latest Resources"
                        onAddToBasket={this.onAddToBasket}
                    />

                    {/* Most popular resources */}
                    <ResourcesOverview
                        user={this.props.user}
                        onFavourite={this.onFavourite}
                        orderBy="order[viewCount]"
                        orderDir="desc"
                        title="Most Popular"
                        onAddToBasket={this.onAddToBasket}
                    />
                    {this.state.resourceIndexParents.length > 0 && (
                        <div className="resource-tree-index-list">
                            <h3>Resource Index</h3>
                            <Row>
                                {this.state.resourceIndexParents.map(
                                    (parent) => (
                                        <ResourceTreeNodeNew
                                            key={parent.id}
                                            node={parent}
                                            onResourceIndexClick={
                                                this.onResourceIndexClick
                                            }
                                        />
                                    )
                                )}
                            </Row>
                        </div>
                    )}
                </>
            );
        } else {
            // When a search has taken place
            if (this.state.activeResourceIndex) {
                preFilterContent = (
                    <>
                        <div className="resource-index-intro">
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: this.state.activeResourceIndex
                                        .description,
                                }}
                            ></div>
                            <div className="resource-index-sub-list resource-tree-index-list">
                                <Row>
                                    {this.state.resourceIndexChildren.map(
                                        (child) => (
                                            <ResourceTreeNodeNew
                                                key={child.id}
                                                node={child}
                                                onResourceIndexClick={
                                                    this.onResourceIndexClick
                                                }
                                            />
                                        )
                                    )}
                                </Row>
                            </div>
                        </div>
                    </>
                );
            }

            pageContent = (
                <>
                    <ResourcesResultsControls
                        totalResults={this.state.totalResults}
                        view={this.state.resultsView}
                        onViewChange={this.onViewChange}
                        onSortChange={this.onSortChange}
                        onDirectionChange={this.onDirectionChange}
                    />

                    <div
                        className={
                            "resource-filter-page-results " +
                            this.state.resultsView +
                            "-view"
                        }
                    >
                        {this.state.totalResults === 0 && (
                            <p>No results found</p>
                        )}

                        {this.state.totalResults > 0 && (
                            <Row>
                                {this.state.resources.map((resource) => (
                                    <ResourceListItem
                                        key={resource.id}
                                        resource={resource}
                                        user={this.props.user}
                                        onFavourite={this.onFavourite}
                                        isFavourite={
                                            favouriteIds.includes(resource.id)
                                                ? true
                                                : false
                                        }
                                        onAddToBasket={this.onAddToBasket}
                                    />
                                ))}
                            </Row>
                        )}
                    </div>
                </>
            );
        }

        return (
            <div
                className={
                    "resources-filter-page" +
                    (this.state.loading ? " is-loading" : "")
                }
            >
                <FilterPage
                    user={this.props.user}
                    onMenuOpen={this.props.onMenuOpen}
                    pageTitle="Resources"
                    categories={this.state.categories}
                    loading={this.state.loading}
                    onCategoryChange={this.onCategoryChange}
                    pageContent={pageContent}
                    preFilterContent={preFilterContent}
                    onCriteriaChange={this.onCriteriaChange}
                    criteria={this.criteria}
                    removeCriteria={this.removeCriteria}
                    clearCriteria={this.clearCriteria}
                    searchInputRef={this.searchInputRef}
                    scrollRef={this.scrollRef}
                    onProfileClick={this.onProfileClick}
                    basket={this.state.basket}
                    basketModalToggle={this.toggleBasketModalPopUp}
                    isResourceIndex={true}
                    isDataIndex={false}
                    isNetworkIndex={false}
                    favouritesLink={true}
                    selectableIds={this.state.selectableIds}
                    searchType="resource"
                    advertBanner={this.state.advertBanner}
                    dataEnabled={true}
                    uploadResourceOnClick={this.toggleForm}
                />

                {this.user && (
                    <>
                        <ResourcesBasketPopUp
                            show={this.state.basketModalShow}
                            close={this.toggleBasketModalPopUp}
                            basket={this.state.basket}
                            onRemoveFromBasket={this.onRemoveFromBasket}
                        />
                        <PopUp
                            header="Upload a Resource"
                            size="lg"
                            content={<ResourceForm />}
                            open={this.state.showForm}
                            close={this.toggleForm}
                        />
                    </>
                )}
            </div>
        );
    }
}

export default ResourcesFilterPage;

