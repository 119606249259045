import React from "react";
import { BrowserRouter, Router, Redirect } from "react-router-dom";
import { TransitionGroup } from "react-transition-group";
import ReactGA from "react-ga4";

import Api from "./Api";

import { UserContext, LoginOnly } from "./UserContext";
import { SettingsContext } from "./SettingsContext";
import CountryProvider from "./GlobeDataContext";

import ColumnManagerWithGlobe from "./components/ColumnManager";

import SplashScreen from "./components/SplashScreen";
import LanguageSelect from "./components/LanguageSelect";
import ProfileLink from "./components/ProfileLink";
import MenuLink from "./components/MenuLink";
import SearchLink from "./components/SearchLink";
import Column from "./components/Column";
import TitlePage from "./pages/TitlePage";
import SearchPage from "./pages/SearchPage";
import InfoPage from "./pages/InfoPage";
import UserPage from "./pages/UserPage";
import DataPage from "./pages/DataPage";
import NewsPage from "./pages/NewsPage";
import FaqPage from "./pages/FaqPage";
import ContactPage from "./pages/ContactPage";
import TextPage from "./pages/TextPage";
import AccountHome from "./components/UserProfile/AccountHome";
import UpdateProfile from "./components/UserProfile/UpdateAccount";
import UpdatePassword from "./components/UserProfile/UpdatePassword";
import ForgottenPassword from "./components/UserProfile/ForgottenPassword";
import BackToAccount from "./components/UserProfile/BackToAccount";
import Favourites from "./components/UserProfile/Favourites";
import Institutions from "./components/UserProfile/Institutions";
import InstitutionCreate from "./components/UserProfile/InstitutionCreate";
import Collaborators from "./components/UserProfile/Collaborators";
import CollaboratorCreate from "./components/UserProfile/CollaboratorCreate";
import Notifications from "./components/UserProfile/Notifications";
import DataCsvForm from "./components/UserProfile/DataCsvForm";
import SavedSearches from "./components/UserProfile/SavedSearches";
import LoginForm from "./components/LoginForm";
import { RegistrationComplete } from "./components/RegistrationComplete";
import NewsArchive from "./components/NewsArchive";
import HomePopUp from "./components/HomePopUp";
import ForumLoginRedirect from "./components/ForumLoginRedirect";
import Courses from "./components/Courses";

import SideMenu from "./components/SideMenu";
import SideBar from "./components/SideBar";
import CountryMenu from "./components/CountryMenu";
import CreateAccountForm from "./components/CreateAccountForm";

import logo from "./assets/logo.png";
import DocumentTitle from "./components/DocumentTitle";
import UpdateUserProfile from "./components/UserProfile/UpdateUserProfile";

import ResourcesFilterPage from "./components/Resources/ResourcesFilterPage";
import DataFilterPage from "./components/Data/DataFilterPage";
import NetworkFilterPage from "./components/Network/NetworkFilterPage";
import ApiInformation from "./components/UserProfile/ApiInformation";
import ModerateGroups from "./components/UserProfile/ModerateGroups";
import CreateResource from "./components/UserProfile/CreateResource";

class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            select: "",
            splash: true,
            isLoading: true,
            pages: null,
            homeContent: null,
            news: null,
            user: null,
            loginUrl: "/log-in",
            menuClass: "",
            dataClass: "",
            dataIndexClass: "",
            resourceTreeClass: "",
            filterPageClass: "",
            menuLinks: false,
            social: false,
            siteOffline: false,
            dataEnabled: true,
        };

        this.loginRefresh = this.loginRefresh.bind(this);

        this.onMenuOpen = () => {
            this.setState({ menuClass: "menu-open" });
        };

        this.onMenuClose = () => {
            this.setState({ menuClass: "" });
        };
    }

    setDataClass = (onlyRemove = false) => {
        if (onlyRemove) {
            this.setState({ dataClass: "" });
        } else {
            this.setState({
                dataClass: this.state.dataClass ? "" : "data-full-width",
            });
        }
    };

    setDataIndexClass = (onlyRemove = false) => {
        if (onlyRemove) {
            this.setState({ dataIndexClass: "" });
        } else {
            this.setState({
                dataIndexClass: this.state.dataIndexClass
                    ? ""
                    : "data-index-full-width",
            });
        }
    };

    setResourceTreeClass = (onlyRemove = false) => {
        if (onlyRemove) {
            this.setState({ resourceTreeClass: "" });
        } else {
            this.setState({
                resourceTreeClass: this.state.resourceTreeClass
                    ? ""
                    : "resource-tree-full-width",
            });
        }
    };

    setFilterPageClass = (onlyRemove = false) => {
        if (onlyRemove) {
            this.setState({ filterPageClass: "" });
        } else {
            this.setState({
                filterPageClass: this.state.filterPageClass
                    ? ""
                    : "filter-page-full-width",
            });
        }
    };

    loginRefresh() {
        return Api.currentUser()
            .then((response) => {
                if (!response) {
                    this.setState({ user: false, loginUrl: "/log-in" });
                } else {
                    if (response.geoLocation === "dynamic") {
                        Api.setCurrentLocation();
                    }

                    const isOfflineForUser =
                        this.isSiteOfflineForUser(response);

                    this.setState({
                        user: response,
                        loginUrl: "/account",
                        isOffline: isOfflineForUser,
                        splash: isOfflineForUser,
                        isLoading: false,
                    });
                }
            })
            .catch((err) => {
                console.error(err);
                this.setState({ user: false, loginUrl: "/log-in" });
            });
    }

    async componentDidMount() {
        Promise.all([
            Api.pages(),
            Api.newsArticles(
                [],
                true,
                true,
                false,
                false,
                false,
                false,
                false,
                true
            ),
            Api.currentUser().catch(() => {}),
            Api.settings().catch(() => {}),
            Api.frontendSettings(),
            Api.homeContents(),
            Api.menuLinks(),
            Api.social(),
            Api.siteOffline(),
        ])
            .then(
                ([
                    pages,
                    news,
                    user,
                    settings,
                    frontendSettings,
                    homeContent,
                    menuLinks,
                    social,
                    siteOffline,
                ]) => {
                    const isSiteOffline = this.isSiteOfflineForUser(
                        user,
                        siteOffline
                    );

                    this.setState({
                        pages,
                        news,
                        user,
                        settings,
                        frontendSettings,
                        homeContent,
                        menuLinks,
                        social,
                        isLoading: isSiteOffline,
                        siteOffline,
                        dataEnabled: siteOffline.isDataEnabled,
                    });

                    const isUserAuthenticated = user !== null;
                    const settingsData = isUserAuthenticated
                        ? settings
                        : frontendSettings;

                    settingsData.forEach((item, index) => {
                        if (
                            item.title === "Google Analytics Code" &&
                            item.value
                        ) {
                            ReactGA.initialize(item.value);
                        }
                    });

                    if (!isSiteOffline) {
                        setTimeout(
                            () => this.setState({ splash: false }),
                            1000
                        );
                    }
                }
            )
            .catch((error) => {
                console.error(error);
            });
    }

    isSiteOfflineForUser = (user, siteOffline = false) => {
        if (user.isAdmin) {
            return false;
        } else {
            if (!siteOffline) {
                siteOffline = this.state.siteOffline;
            }

            return siteOffline?.isOffline;
        }
    };

    closeExtendedTabs = () => {
        this.setDataClass(true);
        this.setDataIndexClass(true);
        this.setResourceTreeClass(true);
    };

    render() {
        let appClass = "App";

        if (this.state.select) appClass += ` ${this.state.select}`;
        if (this.state.menuClass) appClass += ` ${this.state.menuClass}`;
        if (this.state.dataClass) appClass += ` ${this.state.dataClass}`;
        if (this.state.dataIndexClass)
            appClass += ` ${this.state.dataIndexClass}`;
        if (this.state.resourceTreeClass)
            appClass += ` ${this.state.resourceTreeClass}`;
        if (this.state.filterPageClass)
            appClass += ` ${this.state.filterPageClass}`;

        return (
            <BrowserRouter>
                {this.state.splash && (
                    <SplashScreen
                        opacity={this.state.isLoading ? 1 : 0}
                        isOffline={this.state.siteOffline?.isOffline}
                        offlineMessage={this.state.siteOffline?.offlineMessage}
                        loginRefresh={this.loginRefresh}
                        user={this.state.user}
                    />
                )}

                {!this.state.isLoading && (
                    <UserContext.Provider value={this.state.user}>
                        <SettingsContext.Provider value={this.state.settings}>
                            <DocumentTitle />
                            <ColumnManagerWithGlobe
                                news={this.state.news || []}
                                setNoSelect={() =>
                                    this.setState({ select: "no-select" })
                                }
                                setSelect={() => this.setState({ select: "" })}
                            >
                                <HomePopUp
                                    title={this.state.homeContent[0].pageTitle}
                                    caption={
                                        this.state.homeContent[0].pageCaption
                                    }
                                    image={logo}
                                />

                                <div className={appClass}>
                                    <div className="app-buttons d-flex align-items-center">
                                        <MenuLink onClick={this.onMenuOpen} />
                                        <LanguageSelect
                                            user={this.state.user}
                                        />
                                        <ProfileLink />
                                    </div>

                                    <SearchLink />
                                    <div className="main-content">
                                        <Column
                                            route="/menu"
                                            side="left"
                                            mediumUp={1 / 2}
                                            largeUp={1 / 3}
                                        >
                                            <SideMenu
                                                onOpen={this.onMenuOpen}
                                                onClose={this.onMenuClose}
                                                social={this.state.social}
                                                menuLinks={this.state.menuLinks}
                                            />
                                        </Column>
                                        <Column
                                            route={/^\/(home)?$/}
                                            side="left"
                                            mediumUp={1 / 2}
                                            className="title-page-column"
                                        >
                                            <TitlePage
                                                homeContent={
                                                    this.state.homeContent
                                                }
                                                loginUrl={this.state.loginUrl}
                                            />
                                        </Column>

                                        <Column
                                            route="/continent"
                                            mediumUp={1 / 2}
                                            className="country-picker"
                                        >
                                            <CountryProvider
                                                render={(data) => (
                                                    <CountryMenu
                                                        data={data.data}
                                                    />
                                                )}
                                            />
                                        </Column>

                                        <Column
                                            route="/search"
                                            mediumUp={1 / 2}
                                            className="column-search"
                                        >
                                            <SideBar page="Search">
                                                <SearchPage
                                                    user={this.state.user}
                                                />
                                            </SideBar>
                                        </Column>

                                        {this.state.pages.map((page) => (
                                            <>
                                                <Column
                                                    key={`page-${page.id}`}
                                                    route={`/${page.slug}`}
                                                    mediumUp={1}
                                                    largeUp={
                                                        page.width === 0.75
                                                            ? 0.75
                                                            : 0.5
                                                    }
                                                >
                                                    <SideBar>
                                                        <TextPage
                                                            page={page}
                                                            user={
                                                                this.state.user
                                                            }
                                                        />
                                                    </SideBar>
                                                </Column>

                                                {page.width === 0.75 && (
                                                    <Column
                                                        route={`/${page.slug}`}
                                                        side="left"
                                                        largeUp={1 / 4}
                                                        mediumUp={0}
                                                        className="title-page-column courses-title-page inner-title-page"
                                                    >
                                                        <TitlePage
                                                            homeContent={
                                                                this.state
                                                                    .homeContent
                                                            }
                                                            loginUrl={
                                                                this.state
                                                                    .loginUrl
                                                            }
                                                        />
                                                    </Column>
                                                )}
                                            </>
                                        ))}

                                        <Column
                                            route="/faq"
                                            mediumUp={1 / 2}
                                            largeUp={1 / 3}
                                        >
                                            <SideBar page="FAQ" title="FAQ">
                                                <FaqPage />
                                            </SideBar>
                                        </Column>

                                        <Column
                                            route="/contact"
                                            mediumUp={1 / 2}
                                            largeUp={1 / 3}
                                        >
                                            <SideBar page="Contact">
                                                <ContactPage
                                                    user={this.state.user}
                                                />
                                            </SideBar>
                                        </Column>

                                        <Column
                                            route="/profile"
                                            largeUp={1 / 2}
                                            className="column-profile"
                                        >
                                            <LoginOnly>
                                                <InfoPage
                                                    user={this.state.user}
                                                    setDataClass={
                                                        this.setDataClass
                                                    }
                                                    setDataIndexClass={
                                                        this.setDataIndexClass
                                                    }
                                                    setResourceTreeClass={
                                                        this
                                                            .setResourceTreeClass
                                                    }
                                                    loginRefresh={
                                                        this.loginRefresh
                                                    }
                                                    dataEnabled={
                                                        this.state.dataEnabled
                                                    }
                                                />
                                            </LoginOnly>
                                        </Column>

                                        <Column
                                            route="/user"
                                            mediumUp={1 / 2}
                                            className="column-profile"
                                        >
                                            <LoginOnly>
                                                <UserPage
                                                    user={this.state.user}
                                                    setDataClass={
                                                        this.setDataClass
                                                    }
                                                    setDataIndexClass={
                                                        this.setDataIndexClass
                                                    }
                                                    setResourceTreeClass={
                                                        this
                                                            .setResourceTreeClass
                                                    }
                                                />
                                            </LoginOnly>
                                        </Column>

                                        <Column
                                            route="/news-archive"
                                            mediumUp={1 / 2}
                                            largeUp={1 / 3}
                                        >
                                            <SideBar
                                                page="News"
                                                title="Archive"
                                            >
                                                <NewsArchive />
                                            </SideBar>
                                        </Column>

                                        <Column
                                            route={/^\/news(\/?[0-9]{1,10})$/}
                                            mediumUp={1 / 2}
                                            largeUp={1 / 3}
                                        >
                                            <SideBar page="News">
                                                <NewsPage />
                                            </SideBar>
                                        </Column>

                                        <Column
                                            route="/data"
                                            mediumUp={1 / 1}
                                            className="column-data-new"
                                        >
                                            <LoginOnly>
                                                <DataFilterPage
                                                    user={this.state.user}
                                                    loginRefresh={
                                                        this.loginRefresh
                                                    }
                                                    setFilterPageClass={
                                                        this.setFilterPageClass
                                                    }
                                                    onMenuOpen={this.onMenuOpen}
                                                    introContent={
                                                        this.state
                                                            .homeContent[0]
                                                            .dataContent
                                                    }
                                                    dataEnabled={
                                                        this.state.dataEnabled
                                                    }
                                                />
                                            </LoginOnly>
                                        </Column>

                                        <Column
                                            route="/resources"
                                            mediumUp={1 / 1}
                                            className="column-resources-new"
                                        >
                                            <ResourcesFilterPage
                                                setFilterPageClass={
                                                    this.setFilterPageClass
                                                }
                                                onMenuOpen={this.onMenuOpen}
                                                user={this.state.user}
                                                loginRefresh={this.loginRefresh}
                                                introContent={
                                                    this.state.homeContent[0]
                                                }
                                            />
                                        </Column>

                                        <Column
                                            route="/network"
                                            mediumUp={1 / 1}
                                            className="column-network-new"
                                        >
                                            <LoginOnly>
                                                <NetworkFilterPage
                                                    setFilterPageClass={
                                                        this.setFilterPageClass
                                                    }
                                                    onMenuOpen={this.onMenuOpen}
                                                    user={this.state.user}
                                                    loginRefresh={
                                                        this.loginRefresh
                                                    }
                                                    introContent={
                                                        this.state
                                                            .homeContent[0]
                                                    }
                                                />
                                            </LoginOnly>
                                        </Column>

                                        <Column
                                            route="/account"
                                            mediumUp={1 / 2}
                                            largeUp={1 / 3}
                                        >
                                            <LoginOnly>
                                                <SideBar
                                                    page="My Account"
                                                    title=""
                                                    backOnClose={true}
                                                >
                                                    <AccountHome
                                                        loginRefresh={
                                                            this.loginRefresh
                                                        }
                                                        user={this.state.user}
                                                    />
                                                </SideBar>
                                            </LoginOnly>
                                        </Column>

                                        <Column
                                            route="/account/update-details"
                                            mediumUp={1 / 2}
                                        >
                                            <LoginOnly>
                                                <SideBar
                                                    page="My Account"
                                                    title="Update Your Details"
                                                    backOnClose={true}
                                                >
                                                    <UpdateProfile
                                                        loginRefresh={
                                                            this.loginRefresh
                                                        }
                                                        user={this.state.user}
                                                    />
                                                </SideBar>
                                            </LoginOnly>
                                        </Column>

                                        <Column
                                            route="/account/update-password"
                                            mediumUp={1 / 2}
                                            largeUp={1 / 3}
                                        >
                                            <LoginOnly>
                                                <SideBar
                                                    page="My Account"
                                                    title="Update Your Password"
                                                    backOnClose={true}
                                                >
                                                    <UpdatePassword
                                                        reset={false}
                                                        user={this.state.user}
                                                    />
                                                </SideBar>
                                            </LoginOnly>
                                        </Column>

                                        <Column
                                            route="/account/upload-data"
                                            mediumUp={1 / 2}
                                        >
                                            <LoginOnly>
                                                <SideBar
                                                    page="My Account"
                                                    title="Upload Data"
                                                    backOnClose={true}
                                                >
                                                    <DataCsvForm
                                                        loginRefresh={
                                                            this.loginRefresh
                                                        }
                                                    />
                                                    <BackToAccount />
                                                </SideBar>
                                            </LoginOnly>
                                        </Column>

                                        <Column
                                            route="/account/favourites"
                                            mediumUp={1 / 2}
                                            largeUp={1 / 3}
                                        >
                                            <LoginOnly>
                                                <SideBar
                                                    page="My Account"
                                                    title="View Favourites"
                                                    backOnClose={true}
                                                >
                                                    <Favourites
                                                        user={this.state.user}
                                                        loginRefresh={
                                                            this.loginRefresh
                                                        }
                                                    />
                                                </SideBar>
                                            </LoginOnly>
                                        </Column>

                                        <Column
                                            route="/account/saved-searches"
                                            mediumUp={1 / 2}
                                            largeUp={1 / 3}
                                            className="column-saved-searches"
                                        >
                                            <LoginOnly>
                                                <SideBar
                                                    page="My Account"
                                                    title="Saved Searches"
                                                    backOnClose={true}
                                                >
                                                    <SavedSearches
                                                        user={this.state.user}
                                                        loginRefresh={
                                                            this.loginRefresh
                                                        }
                                                    />
                                                </SideBar>
                                            </LoginOnly>
                                        </Column>

                                        <Column
                                            route="/account/institutions"
                                            mediumUp={1 / 2}
                                            largeUp={1 / 3}
                                        >
                                            <LoginOnly>
                                                <SideBar
                                                    page="My Account"
                                                    title="Manage Institutions"
                                                    backOnClose={true}
                                                >
                                                    <Institutions
                                                        user={this.state.user}
                                                    />
                                                </SideBar>
                                            </LoginOnly>
                                        </Column>

                                        <Column
                                            route="/account/collaborators"
                                            mediumUp={1 / 2}
                                            largeUp={1 / 3}
                                        >
                                            <LoginOnly>
                                                <SideBar
                                                    page="My Account"
                                                    title="Manage Collaborators"
                                                    backOnClose={true}
                                                >
                                                    <Collaborators
                                                        user={this.state.user}
                                                    />
                                                </SideBar>
                                            </LoginOnly>
                                        </Column>

                                        <Column
                                            route="/account/create-institution"
                                            mediumUp={1 / 2}
                                        >
                                            <LoginOnly>
                                                <SideBar
                                                    page="Create New Institution"
                                                    backOnClose={true}
                                                >
                                                    <InstitutionCreate />
                                                </SideBar>
                                            </LoginOnly>
                                        </Column>

                                        <Column
                                            route="/account/create-collaborator"
                                            mediumUp={1 / 2}
                                        >
                                            <LoginOnly>
                                                <SideBar
                                                    page="Create New Collaborator"
                                                    backOnClose={true}
                                                >
                                                    <CollaboratorCreate />
                                                </SideBar>
                                            </LoginOnly>
                                        </Column>

                                        <Column
                                            route="/account/update-profile"
                                            mediumUp={1 / 2}
                                        >
                                            <LoginOnly>
                                                <SideBar
                                                    page="Update Profile"
                                                    backOnClose={true}
                                                >
                                                    <UpdateUserProfile
                                                        user={this.state.user}
                                                        loginRefresh={
                                                            this.loginRefresh
                                                        }
                                                    />
                                                </SideBar>
                                            </LoginOnly>
                                        </Column>

                                        <Column
                                            route="/account/notifications"
                                            mediumUp={1 / 3}
                                        >
                                            <LoginOnly>
                                                <SideBar
                                                    page="My Account"
                                                    title="Notifications"
                                                    backOnClose={true}
                                                >
                                                    <Notifications
                                                        user={this.state.user}
                                                        loginRefresh={
                                                            this.loginRefresh
                                                        }
                                                    />
                                                </SideBar>
                                            </LoginOnly>
                                        </Column>

                                        <Column
                                            route="/account/api-information"
                                            mediumUp={1 / 2}
                                            className="api-information"
                                        >
                                            <LoginOnly>
                                                <SideBar
                                                    page="My Account"
                                                    title="API Information"
                                                    backOnClose={true}
                                                >
                                                    <ApiInformation
                                                        content={
                                                            this.state
                                                                .homeContent[0]
                                                        }
                                                    />
                                                </SideBar>
                                            </LoginOnly>
                                        </Column>

                                        <Column
                                            route="/log-in"
                                            mediumUp={1 / 2}
                                            largeUp={1 / 3}
                                        >
                                            <SideBar
                                                page="Network"
                                                title="Log In"
                                            >
                                                <LoginForm
                                                    loginRefresh={
                                                        this.loginRefresh
                                                    }
                                                />
                                            </SideBar>
                                        </Column>

                                        <Column
                                            route="/forgotten-password"
                                            mediumUp={1 / 2}
                                            largeUp={1 / 3}
                                        >
                                            <SideBar
                                                page="Network"
                                                title="Forgotten Password"
                                            >
                                                <ForgottenPassword />
                                            </SideBar>
                                        </Column>

                                        <Column
                                            route={/^\/reset-password(\/?.*)$/}
                                            mediumUp={1 / 2}
                                            largeUp={1 / 3}
                                        >
                                            <SideBar
                                                page="Network"
                                                title="Reset Password"
                                            >
                                                <UpdatePassword reset={true} />
                                            </SideBar>
                                        </Column>

                                        <Column
                                            route="/register"
                                            mediumUp={1 / 2}
                                        >
                                            <SideBar
                                                page="Network"
                                                title="Register"
                                            >
                                                <CreateAccountForm />
                                            </SideBar>
                                        </Column>

                                        <Column
                                            route="/registration-complete"
                                            mediumUp={1 / 2}
                                            largeUp={1 / 3}
                                        >
                                            <SideBar
                                                page="Network"
                                                title="Register"
                                            >
                                                <RegistrationComplete />
                                            </SideBar>
                                        </Column>

                                        <Column
                                            route="/forum-login"
                                            mediumUp={1 / 1}
                                        >
                                            <LoginOnly>
                                                <ForumLoginRedirect
                                                    user={this.state.user}
                                                />
                                            </LoginOnly>
                                        </Column>

                                        <Column
                                            route="/courses"
                                            largeUp={3 / 4}
                                            mediumUp={1 / 1}
                                            className="courses-page"
                                        >
                                            <SideBar title="Courses">
                                                <LoginOnly>
                                                    <Courses
                                                        pageContent={
                                                            this.state
                                                                .homeContent[0]
                                                        }
                                                    />
                                                </LoginOnly>
                                            </SideBar>
                                        </Column>

                                        <Column
                                            route="/courses"
                                            side="left"
                                            largeUp={1 / 4}
                                            mediumUp={0}
                                            className="title-page-column courses-title-page"
                                        >
                                            <TitlePage
                                                homeContent={
                                                    this.state.homeContent
                                                }
                                                loginUrl={this.state.loginUrl}
                                            />
                                        </Column>
                                        <Column
                                            route="/account/moderator"
                                            mediumUp={1 / 3}
                                        >
                                            <LoginOnly>
                                                <SideBar
                                                    page="Access Control"
                                                    title="Moderate Groups"
                                                    backOnClose={true}
                                                >
                                                    <ModerateGroups
                                                        user={this.state.user}
                                                    />
                                                </SideBar>
                                            </LoginOnly>
                                        </Column>
                                        <Column
                                            route="/account/create-resource"
                                            smallUp={2 / 3}
                                            largeUp={1 / 3}
                                        >
                                            <LoginOnly>
                                                <SideBar
                                                    page="Resources"
                                                    title="Create a Resource"
                                                    backOnClose={true}
                                                >
                                                    <CreateResource
                                                        user={this.state.user}
                                                    />
                                                </SideBar>
                                            </LoginOnly>
                                        </Column>
                                    </div>
                                </div>
                            </ColumnManagerWithGlobe>
                        </SettingsContext.Provider>
                    </UserContext.Provider>
                )}
            </BrowserRouter>
        );
    }
}

export default App;

