import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { faTimesSquare } from "@fortawesome/pro-solid-svg-icons";

class FilterPageCriteria extends React.Component {
    render() {
        return (
            <div className="filter-page-criteria">
                <div>
                    <div className="label">Selected Filters:</div>
                    <div
                        className="clear-search"
                        onClick={() => this.props.clearCriteria()}
                    >
                        <FontAwesomeIcon icon={faTimesSquare} />
                        Clear Search
                    </div>
                </div>
                <ul className="search-criteria-list list-inline">
                    {this.props.criteria &&
                        this.props.criteria.map((criteria) => (
                            <li
                                key={criteria.id}
                                className={`list-inline-item py-2 px-3 m-1`}
                            >
                                {criteria.profileType.title}:{" "}
                                <span>{criteria.title}</span>
                                <FontAwesomeIcon
                                    className="close-icon ml-3"
                                    icon={faTimes}
                                    data-filtervalue={criteria.title}
                                    onClick={() =>
                                        this.props.removeCriteria(criteria)
                                    }
                                />
                            </li>
                        ))}
                </ul>
            </div>
        );
    }
}

export default FilterPageCriteria;

