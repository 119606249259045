import React from "react";
import FilterPageCategories from "./FilterPageCategories";
import FilterPageCriteria from "./FilterPageCriteria";
import FilterPageBtn from "./FilterPageBtn";
import {
    faMapMarkerAlt,
    faStar,
    faCloudDownloadAlt,
    faCartArrowDown,
    faUpload,
    faQuestion,
} from "@fortawesome/pro-solid-svg-icons";
import Loading from "../Loading";

class FilterPageContent extends React.Component {
    render() {
        return (
            <div className="filter-page-content">
                <div
                    className="filter-page-content__header"
                    ref={this.props.scrollRef}
                >
                    <div className="filter-page-content__header-title">
                        <h1>{this.props.pageTitle}</h1>

                        {this.props.loading === true && <Loading size="2x" />}
                    </div>

                    <div className="data-header-btns">
                        <div className="header-btns-wrap">
                            {this.props.basket &&
                                this.props.basket.length > 0 && (
                                    <FilterPageBtn
                                        title={
                                            "Download Selected Resources (" +
                                            this.props.basket.length +
                                            ")"
                                        }
                                        icon={faCartArrowDown}
                                        onClick={this.props.basketModalToggle}
                                        element="div"
                                    />
                                )}

                            {this.props.favouritesLink === true &&
                                this.props.user && (
                                    <>
                                        <FilterPageBtn
                                            title="Upload a Resource"
                                            element="div"
                                            icon={faUpload}
                                            onClick={
                                                this.props.uploadResourceOnClick
                                            }
                                        />
                                        <FilterPageBtn
                                            title={"Favourites"}
                                            icon={faStar}
                                            to="/account/favourites"
                                            element="link"
                                            className="ml-2"
                                        />
                                    </>
                                )}
                        </div>

                        {this.props.resultCount && this.props.exportUrl && (
                            <>
                                <select
                                    name="mapType"
                                    onChange={this.props.changeHeatMapType}
                                >
                                    <option value="abundance">Abundance</option>
                                    <option value="bloodmeal-host">
                                        Bloodmeal host
                                    </option>
                                    <option value="insecticide-resistance">
                                        Insecticide resistance
                                    </option>
                                    <option value="pathogen-detection">
                                        Pathogen detection
                                    </option>
                                </select>

                                <FilterPageBtn
                                    title={
                                        (this.props.heatMapStatus
                                            ? "Hide"
                                            : "Show") + " Map"
                                    }
                                    icon={faMapMarkerAlt}
                                    element="div"
                                    onClick={this.props.showHeatMap}
                                />

                                <FilterPageBtn
                                    title={
                                        "Download Data (" +
                                        this.props.resultCount +
                                        " results)"
                                    }
                                    icon={faCloudDownloadAlt}
                                    href={this.props.exportUrl}
                                    target="_blank"
                                    element="a"
                                />
                            </>
                        )}

                        {this.props.uploadCta === true && (
                            <>
                                <FilterPageBtn
                                    title="Upload Your Data"
                                    element="div"
                                    icon={faUpload}
                                    onClick={this.props.uploadCtaOnClick}
                                />
                                <FilterPageBtn
                                    title="Help"
                                    element="div"
                                    icon={faQuestion}
                                    onClick={this.props.showIntro}
                                />
                            </>
                        )}
                    </div>
                </div>

                {this.props.categories !== false &&
                    this.props.categories.length > 0 && (
                        <FilterPageCategories
                            categories={this.props.categories}
                            onCategoryChange={this.props.onCategoryChange}
                        />
                    )}

                {this.props.preFilterContent && (
                    <div className="filter-page-content__pre-filters">
                        {this.props.preFilterContent}
                    </div>
                )}

                {this.props.criteria !== false &&
                    this.props.criteria.length > 0 && (
                        <FilterPageCriteria
                            criteria={this.props.criteria}
                            removeCriteria={this.props.removeCriteria}
                            clearCriteria={this.props.clearCriteria}
                        />
                    )}

                {this.props.pageContent && (
                    <div className="filter-page-content__content">
                        {this.props.pageContent}
                    </div>
                )}
            </div>
        );
    }
}

export default FilterPageContent;
