import React from "react";
import Api from "../../Api";
import ResourcesListItem from "./ResourceListItem";
import { Row } from "react-bootstrap";

class ResourcesOverview extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            resources: false,
        };
    }

    componentDidMount() {
        let query = {
            perPage: 3,
            page: 1,
            pagination: true,
            isEnabled: true,
        };

        query[this.props.orderBy] = this.props.orderDir;

        Api.resources(query).then((resources) => {
            this.setState({ resources });
        });
    }

    render() {
        let favouriteIds = [];

        if (this.props.user && this.props.user.favourites) {
            this.props.user.favourites.forEach((item, index) => {
                favouriteIds.push(item.id);
            });
        }

        return (
            <div className="filter-page-resources__overview">
                <h3>{this.props.title}</h3>
                {this.state.resources !== false && (
                    <div className="filter-page-resources__overview-list">
                        <Row>
                            {this.state.resources.map((resource) => (
                                <ResourcesListItem
                                    key={resource.id}
                                    resource={resource}
                                    user={this.props.user}
                                    onFavourite={this.props.onFavourite}
                                    isFavourite={
                                        favouriteIds.includes(resource.id)
                                            ? true
                                            : false
                                    }
                                    onAddToBasket={this.props.onAddToBasket}
                                />
                            ))}
                        </Row>
                    </div>
                )}
            </div>
        );
    }
}

export default ResourcesOverview;
